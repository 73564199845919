import {setUser} from "../actions";
import {connect} from "react-redux";
import Validator from "validatorjs";
import classNames from "classnames";
import React, {Component} from 'react';
import {withRouter} from "react-router";
import PageTemplate from "./PageTemplate";
import ValidatedComponent from "../components/ValidatedComponent";

class FormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      formRules: {
        firstname: "required",
        lastname: "required",
        company: "required",
        jobtitle: "required",
        email: "required|email",
      },
      submit: false
    }
  }

  /**
   *
   */
  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({formErrors: null});

    const data = {
      firstname: document.getElementById('form-firstname').value,
      lastname: document.getElementById('form-lastname').value,
      company: document.getElementById('form-company').value,
      jobtitle: document.getElementById('form-job-title').value,
      email: document.getElementById('form-email').value,
    };

    const validation = new Validator(data, this.state.formRules);

    this.setState({formErrors: validation.errors.all()});

    if (validation.passes()) {
      this.props.setUser(data);

      const fields = this.props.responses.map(res => ({
        name: 'q' + res.question_id,
        value: res.response
      }));

      Object
        .keys(data)
        .forEach(key => {
          fields.push({
            name: key,
            value: data[key]
          });
        });

      this.setState({submit: true});

      fetch('https://api.hsforms.com/submissions/v3/integration/submit/390615/0d607ad8-49d1-41bd-95d2-471b2738593e', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({fields}),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          this.props.history.push('/results/overall')
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const btnSubmitClass = classNames(
      "hover:shadow-lg relative border-2 border-white rounded-lg gloss-effect transition duration-100 ease-in-out focus:outline-none",
      {"cursor-wait" : this.state.submit}
    );

    return (
      <PageTemplate>
        <div className="bg-brand-blue relative">
          <div className="lg:hidden arrow-down absolute top-full transform translate-x-1/2 right-1/2"></div>
          <div className="lg:w-7/12 mx-auto">
            <div className="px-8 lg:px-4 pt-8 lg:pt-6 pb-10 lg:pb-12">

              <div className="w-100 lg:max-w-xs mx-auto lg:flex items-center lg:mt-1">
                <div className="h-3 bg-white flex-1">
                  <div className="bg-brand-secondary h-full" style={{width: "100%"}}></div>
                </div>
                <div className="pt-2 lg:pt-0 lg:pl-4 text-xs lg:-mr-3 font-light">
                  100%
                </div>
              </div>

              <p className="lg:text-2xl mt-6 lg:leading-8 text-center">
                Please complete the form to review your assessment results.
              </p>
            </div>
          </div>
        </div>

        <form className="lg:w-6/12 xl:max-w-3xl lg:mx-auto px-8 py-10">
          <div className="flex flex-col lg:flex-row -mx-3">
            <div className="lg:w-1/2 mb-5 px-3">
              <label className="block text-gray-700 text-xs font-bold mb-2" htmlFor="form-firstname">
                First Name*
              </label>
              <ValidatedComponent
                name="firstname"
                errors={this.state.formErrors}
                className="appearance-none border rounded w-full py-2 lg:py-1 px-3 leading-tight focus:outline-none focus:shadow-outline"
                renderComponent={cn => <input className={cn} id="form-firstname" type="text"/>}
              />
            </div>
            <div className="lg:w-1/2 mb-5 px-3">
              <label className="block text-gray-700 text-xs font-bold mb-2" htmlFor="form-lastname">
                Last Name*
              </label>
              <ValidatedComponent
                name="lastname"
                errors={this.state.formErrors}
                className="appearance-none border rounded w-full py-2 lg:py-1 px-3 leading-tight focus:outline-none focus:shadow-outline"
                renderComponent={cn => <input className={cn} id="form-lastname" type="text"/>}
              />
            </div>
          </div>

          <div className="lg:w-3/4">
            <div className="mb-5">
              <label className="block text-gray-700 text-xs font-bold mb-2" htmlFor="form-company">
                Company*
              </label>
              <ValidatedComponent
                name="company"
                errors={this.state.formErrors}
                className="appearance-none border rounded w-full py-2 lg:py-1 px-3 leading-tight focus:outline-none focus:shadow-outline"
                renderComponent={cn => <input className={cn} id="form-company" type="text"/>}
              />
            </div>
            <div className="mb-5">
              <label className="block text-gray-700 text-xs font-bold mb-2" htmlFor="form-job-title">
                Job Title*
              </label>
              <ValidatedComponent
                name="jobtitle"
                errors={this.state.formErrors}
                className="appearance-none border rounded w-full py-2 lg:py-1 px-3 leading-tight focus:outline-none focus:shadow-outline"
                renderComponent={cn => <input className={cn} id="form-job-title" type="text"/>}
              />
            </div>
            <div className="mb-5">
              <label className="block text-gray-700 text-xs font-bold mb-2" htmlFor="form-email">
                Email*
              </label>
              <ValidatedComponent
                name="email"
                errors={this.state.formErrors}
                className="appearance-none border rounded w-full py-2 lg:py-1 px-3 leading-tight focus:outline-none focus:shadow-outline"
                renderComponent={cn => <input className={cn} id="form-email" type="email"/>}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-start lg:items-center mt-2">
            <button type="submit" onClick={this.handleSubmit} disabled={this.state.submit}
                    className={btnSubmitClass}>
              <span className="flex items-center py-1 lg:py-0 px-3 lg:text-lg relative z-10 font-light">
                Review My Results
                <i className="mdi mdi-arrow-right-bold text-white text-3xl lg:text-2xl ml-1"></i>
              </span>
            </button>
            {Object.keys(this.state.formErrors).length > 0 && (
              <p className="text-red-600 text-xs mt-5 lg:mt-0 lg:ml-5">Please check form for errors</p>
            )}
          </div>
        </form>
      </PageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  responses: state.responses,
});

const mapDispatchToProps = dispatch => ({
  setUser: data => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(FormPage)
)
