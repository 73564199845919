import App from './App';
import './css/tailwind.output.css';
import './css/style.css';
import React from 'react';
import './helpers/screens';
import ReactDOM from 'react-dom';
import logger from 'redux-logger';
import rootReducer from './reducers';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, createStore } from 'redux';

const store = createStore(
  rootReducer,
  applyMiddleware(logger)
);

window.store = store;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
