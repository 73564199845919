import React, {Component} from 'react';
import PageTemplate from "./PageTemplate";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import classNames from "classnames";

class ConsultationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
    }
  }

  /**
   *
   * @param level
   * @returns {Function}
   */
  handleBtnClick = (level) => {
    return () => {
      if (this.props.user && this.props.user.email) {
        this.setState({submit: true});

        const fields = [
          {
            name: 'email',
            value: this.props.user.email
          }
        ];

        const guid = level === 1
          ? "e93fac6c-78c6-42d6-aaf7-40826c6fa451"
          : "8771c7d0-54f9-4e55-a23f-1ea8a0c7273b";

        fetch('https://api.hsforms.com/submissions/v3/integration/submit/390615/' + guid, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({fields}),
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            this.props.history.push('/thankyou')
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const btnClass = classNames(
      "hover:shadow-lg relative border-2 border-white rounded-lg gloss-effect transition duration-100 ease-in-out focus:outline-none",
      {"cursor-wait" : this.state.submit}
    );
    return (
      <PageTemplate>
        <section className="container px-8 lg:px-6 mx-auto mt-6 lg:mt-12">
          <p className="py-5 lg:text-2xl font-light">
           There are two things that you can do to improve your strategy, <br
            className="hidden lg:block"/>
            and as a result, your learning team’s performance.
          </p>
        </section>

        <section className="container px-8 lg:px-6 mx-auto">
          <div className="border-t pb-10 lg:py-10 flex flex-col-reverse lg:flex-row">
            <div className="lg:w-7/12">
              <div className="lg:w-11/12">
                <h2 className="text-xl lg:text-2xl mb-2 lg:mb-5 font-light">
                  <strong className="font-bold">Gap Analysis</strong>
                </h2>
                <p className="lg:text-xl mb-4 font-light">
                  You’ve taken the Strategy Diagnostic and you want to go deeper. Our Gap Analysis package, which
                  includes a 360-degree survey, will help you uncover opportunities for growth.
                </p>
                <p className="lg:text-xl font-light">
                  <strong className="font-bold">How it works:</strong> We’ll work with your organization’s leadership
                  and L&D team members to identify survey participants, administer the survey, analyze your results and
                  deliver a comprehensive report. Our analysis will help you prioritize and address the gaps that have
                  the greatest business impact.
                </p>
                <div className="h-8"></div>
                <button type="button" onClick={this.handleBtnClick(1)} className={btnClass}>
                  <span className="flex items-center py-1 lg:py-0 px-3 lg:text-lg relative z-10 font-light">
                    Request more information
                    <i className="mdi mdi-arrow-right-bold text-white text-3xl lg:text-2xl ml-1"></i>
                  </span>
                </button>
              </div>
            </div>
            <div className="lg:w-5/12">
              <img src="/img/consultationpage_graphics-01.svg" alt=""
                   className="transform lg:origin-right scale-90 lg:scale-110 mb-2 lg:mb-5 translate-x-8 lg:translate-x-0"/>
            </div>
          </div>
        </section>

        <section className="container px-8 lg:px-6 mx-auto">
          <div className="border-t pt-3 pb-12 lg:py-12 flex flex-col lg:flex-row">
            <div className="lg:w-5/12">
              <img src="/img/consultationpage_graphics-02.svg" alt=""
                   className="transform lg:origin-left lg:scale-110 -mb-2 lg:mb-5 translate-x-3"/>
            </div>
            <div className="lg:w-7/12">
              <div className="lg:ml-auto lg:w-11/12">
                <h2 className="text-xl lg:text-2xl mb-2 lg:mb-5 font-light">
                  <strong className="font-bold">The Strategic Plan</strong>
                </h2>
                <p className="lg:text-xl mb-4 font-light">
                  Once you have a thorough understanding of your organization’s gaps, we will develop a strategic plan
                  to address them and improve your L&D team’s performance.
                </p>
                <p className="lg:text-xl font-light">
                  <strong className="font-bold">How it works:</strong> Our team of experienced life science consultants
                  will conduct stakeholder interviews to dive deeper into the gaps uncovered in the Gap Analysis. Findings
                  uncovered in stakeholder interviews inform the creation of a strategic plan, which will provide
                  targeted guidance for advancing your L&D team.
                </p>
                <div className="h-8"></div>
                <button type="button" onClick={this.handleBtnClick(2)} className={btnClass}>
                  <span className="flex items-center py-1 lg:py-0 px-3 lg:text-lg relative z-10 font-light">
                    Request more information
                    <i className="mdi mdi-arrow-right-bold text-white text-3xl lg:text-2xl ml-1"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </section>
      </PageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(ConsultationPage)
);
