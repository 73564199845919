/**
 *
 * @param question
 * @param response
 * @returns {{data: {category_id: (*|string), response: *, question_id: *}, type: string}}
 */
export const setResponse = (question, response) => ({
  type: 'SET_RESPONSE',
  data: {
    category_id: question.category_id,
    question_id: question.id,
    response
  }
});

/**
 *
 * @type {number}
 */
let nextQuestionId = 0;

/**
 *
 * @returns {{data: number, type: string}}
 */
export const nextQuestion = () => ({
  type: 'NEXT_QUESTION',
  data: nextQuestionId++
});

/**
 *
 * @param data
 * @returns {{data: *, type: string}}
 */
export const setCategory = data => ({
  type: 'SET_CATEGORY',
  data
});

/**
 *
 * @param data
 * @returns {{data: *, type: string}}
 */
export const setUser = data => ({
  type: 'SET_USER',
  data
});
