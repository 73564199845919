const theme = {
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  }
};

function init() {
  const screens = Object.keys(theme.screens).reverse();

  screens.forEach(function(el) {
    const mql = window.matchMedia('(min-width: '+ theme.screens[el] +')');
    if(mql.matches) {
      document.querySelector("html").classList.add(el);
    }
  });
}

window.onresize = init;
init();

export function getScreenSize() {
  const screens = Object.keys(theme.screens).reverse();

  for(let i = 0; i < screens.length; i++) {
    const html = document.querySelector("html");
    if(html.classList.contains(screens[i])) {
      return screens[i];
    }
  }
  return "sm";
}
