export default [
  {
    label: 'Strongly Disagree',
    value: 1,
  },
  {
    label: 'Somewhat Disagree',
    value: 2,
  },
  {
    label: 'Somewhat Agree',
    value: 3,
  },
  {
    label: 'Strongly Agree',
    value: 4,
  },
];
