import React from 'react';
import HomePage from "./pages/HomePage";
import FormPage from "./pages/FormPage";
import RouteGuard from "./components/RouteGuard";
import QuestionsPage from "./pages/QuestionsPage";
import ConsultationPage from "./pages/ConsultationPage";
import ThankYouPage from "./pages/ThankYouPage";
import ResultsOverallPage from "./pages/ResultsOverallPage";
import ResultsCategoryPage from "./pages/ResultsCategoryPage";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';

export default function App() {
  return (
    <BrowserRouter>
      <Route
        render={({ location }) => {
          let background = location.state && location.state.background;

          return (
            <PageTransition
              preset="fadeLeftFadeRight"
              transitionKey={background ? background.pathname : location.pathname}
            >
              <Switch location={background || location}>
                <Route exact path="/" component={HomePage} />
                <RouteGuard exact path="/form" component={FormPage} />
                <Route exact path="/questions" component={QuestionsPage} />
                <RouteGuard exact path="/consultation" component={ConsultationPage} />
                <RouteGuard exact path="/thankyou" component={ThankYouPage} />
                <RouteGuard exact path="/results/overall" component={ResultsOverallPage} />
                <RouteGuard exact path="/results/category" component={ResultsCategoryPage} />
              </Switch>
            </PageTransition>
          );
        }}
      />
    </BrowserRouter>
  );
}
