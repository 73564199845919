import {connect} from "react-redux";
import React, {Component} from 'react';
import {Redirect, Route} from "react-router";

class RouteGuard extends Component {
  /**
   *
   * @returns {*}
   */
  render() {
    const isSurveyTaken = this.props.questions.length === this.props.responses.length;

    if (isSurveyTaken) {
      return (
        <Route {...this.props}/>
      );
    }

    return <Redirect to={{ pathname: '/questions' }} replace />;
  }
}

const mapStateToProps = state => ({
  questions: state.questions,
  responses: state.responses,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RouteGuard);
