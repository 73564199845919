/**
 *
 * @returns {*[]}
 */
export function fetchQuestions() {
  return [
    {
      id: '1',
      category_id: '1',
      question: 'The vision for your L&D organization is clearly defined. In other words, you can articulate what you want your organization to be and achieve in the future.',
    },
    {
      id: '2',
      category_id: '1',
      question: 'L&D team members understand the vision and how their work contributes to it.',
    },
    {
      id: '3',
      category_id: '5',
      question: 'Stakeholders understand the level of service the L&D team is responsible for providing. In other words, they know what you are supposed to deliver and by when. They also know when they are requesting something that is outside the agreed upon level of service.',
    },
    {
      id: '4',
      category_id: '5',
      question: 'The timelines for meeting stakeholder requests are usually realistic.',
    },
    {
      id: '5',
      category_id: '4',
      question: 'The L&D team consistently meets demand from stakeholders. In other words, you have adequate resources (e.g., staff, budget) to provide agreed upon services and address all reasonable requests from stakeholders.',
    },
    {
      id: '6',
      category_id: '3',
      question: 'When L&D team members interact with stakeholders they operate as "strategic business partners" rather than "order takers." This means that they are capable of diagnosing learning needs, communicating  potential solutions, and advocating for instructionally sound learning solutions...rather than just "building what the stakeholder wants."',
    },
    {
      id: '7',
      category_id: '5',
      question: 'Stakeholders recognize the value of the L&D organization and the services it provides. For example, senior stakeholders provide positive feedback regarding your team\'s performance.',
    },
    {
      id: '8',
      category_id: '2',
      question: 'Stakeholders and learners are satisfied the with quality of services the L&D team provides. For example, they believe that the learning resources you create are routinely engaging and impactful.',
    },
    {
      id: '9',
      category_id: '2',
      question: 'Core programs such as new hire training and manager training produce employees who can demonstrate desired behaviors and drive business outcomes.',
    },
    {
      id: '10',
      category_id: '3',
      question: 'The L&D organization has a “seat at the table” when it comes to discussing strategic issues that impact L&D and the stakeholder groups it serves.',
    },
    {
      id: '11',
      category_id: '4',
      question: 'L&D team members rarely or never feel overworked.',
    },
    {
      id: '12',
      category_id: '6',
      question: 'L&D team members can clearly and accurately articulate the characteristics of the organization\'s working culture.',
    },
    {
      id: '13',
      category_id: '6',
      question: 'Behaviors that support the L&D organization’s desired working culture are positively reinforced.',
    },
  ];
}
