import {connect} from "react-redux";
import scores from "../consts/scores";
import React, {Component} from 'react';
import {getScorePercent} from "../helpers/tools";

class ScoreCard extends Component {
  /**
   *
   * @returns {null|*}
   */
  render() {
    if (!this.props.category) return null;

    const maxScore = scores.slice().pop();
    const score = getScorePercent(this.props.category, this.props.questions, maxScore);

    return (
      <div className="fixed lg:absolute top-0 z-50 left-0 p-5 lg:-m-5 overflow-y-auto h-full lg:h-auto">
        <div className="bg-white container md:max-w-sm rounded-lg shadow-md">
          <div className="border border-gray-400 relative rounded-lg">
            <div className="cursor-pointer z-50 absolute right-0 top-0 m-3 lg:m-4">
              <i className="mdi mdi-close text-3xl lg:text-4xl leading-none text-shadow"></i>
            </div>

            <div className="p-6 lg:p-10 infographic-details">
              <h3 className="text-xl lg:text-2xl font-bold text-brand-primary mt-3">
                {this.props.category.name} — {score}%
              </h3>

              <div dangerouslySetInnerHTML={{__html: this.props.category.message}} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  questions: state.questions,
  category: state.category,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ScoreCard);
