import {fetchQuestions} from "../factories/questions";
import {fetchCategories} from "../factories/categories";

/**
 *
 */
const initialState = {
  categories: fetchCategories(),
  questions: fetchQuestions(),
  question: null,
  category: null,
  responses: [],
  user: null,
};

/**
 *
 * @param state
 * @param action
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RESPONSE': {
      const responses = [...state.responses, action.data];
      const categories = state.categories.map(category => {
        const score = responses
          .filter(res => res.category_id === category.id)
          .map(res => res.response)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        return {
          ...category,
          score,
        }
      });

      return {
        ...state,
        responses,
        categories,
      };
    }

    case 'NEXT_QUESTION':
      return {
        ...state,
        question: state.questions[action.data] || initialState.question
      };

    case 'SET_CATEGORY':
      return {
        ...state,
        category: action.data
      };

    case 'SET_USER':
      return {
        ...state,
        user: action.data
      };

    default:
      return state
  }
};
