/**
 *
 * @returns {*[]}
 */
export function fetchCategories() {
  return [
    {
      id: '1',
      name: 'Vision',
      message: `
        <p class="font-light mt-5 text-sm lg:text-xs lg:w-11/12">
          Your vision is what you want your learning organization to be and achieve in the future. When your
          vision is not well-defined, you may experience things like:
        </p>

        <ul class="list-disc text-sm lg:text-xs ml-4 font-light lg:w-11/12">
          <li class="mt-3">
            Confusion among the L&D team about the organization’s priorities and objectives
          </li>
          <li class="mt-3">
            Difficulty prioritizing initiatives for the L&D organization due to lack of clarity about what will
            have the most impact
          </li>
        </ul>
      `
    },
    {
      id: '2',
      name: 'Services and Deliverables',
      message: `
        <p class="font-light mt-5 text-sm lg:text-xs lg:w-11/12">
          Services and Deliverables are the things you’re responsible for providing to stakeholders and learners.
          Signs you may be facing challenges with your Services and Deliverables include:
        </p>

        <ul class="list-disc text-sm lg:text-xs ml-4 font-light lg:w-11/12">
          <li class="mt-3">
            Core programs such as new hire training or manager training have room for improvement
          </li>
          <li class="mt-3">
            Learning content doesn’t consistently meet the needs of the audience – for example, it may not be designed for the “modern learner”
          </li>
          <li class="mt-3">
            Practices that support Meeting Planning &amp; Logistics could benefit from more standardization and efficiency.
            For example, you find that team members are often “reinventing the wheel” when it comes to things like scheduling and planning for new hire training
          </li>
        </ul>
      `
    },
    {
      id: '3',
      name: 'Capabilities',
      message: `
        <p class="font-light mt-5 text-sm lg:text-xs lg:w-11/12">
          Capabilities are the people, processes and technology required to consistently provide quality Services and Deliverables.
          When your Capabilities are not up to par, you may face issues such as:
        </p>

        <ul class="list-disc text-sm lg:text-xs ml-4 font-light lg:w-11/12">
          <li class="mt-3">
            L&D team members producing Services and Deliverables that are not up to the organization’s standards and do not meet business or learner needs
          </li>
          <li class="mt-3">
            Team members reacting to requests rather than providing consultation
          </li>
          <li class="mt-3">
            Technology limitations that negatively impact the L&D team’s ability to deliver the most effective learning solutions
          </li>
        </ul>
      `
    },
    {
      id: '4',
      name: 'Org. Structure',
      message: `
        <p class="font-light mt-5 text-sm lg:text-xs lg:w-11/12">
          Organizational Structure defines your learning organization’s roles, reporting relationships and responsibilities.
          Signs you may be facing challenges with your Organizational Structure include:
        </p>

        <ul class="list-disc text-sm lg:text-xs ml-4 font-light lg:w-11/12">
          <li class="mt-3">
            L&D team members are overworked and frustrated, and employee engagement is low
          </li>
          <li class="mt-3">
            The L&D team suffers from high turnover resulting from positive (e.g., promotions) or negative (e.g., exiting the org, underperformance) situations
          </li>
          <li class="mt-3">
            The skills and competencies of the L&D team do not match what the business needs
          </li>
        </ul>
      `
    },
    {
      id: '5',
      name: 'Organization Dynamics',
      message: `
        <p class="font-light mt-5 text-sm lg:text-xs lg:w-11/12">
          Organization Dynamics define how the learning organization interacts with other business units and functions.
          Signs you may be facing challenges with your Organization Dynamics include:
        </p>

        <ul class="list-disc text-sm lg:text-xs ml-4 font-light lg:w-11/12">
          <li class="mt-3">
            Stakeholders often set unrealistic timelines and are frustrated when the L&D team cannot meet them
          </li>
          <li class="mt-3">
            Stakeholders often request services that the L&D team isn’t responsible for providing
          </li>
          <li class="mt-3">
            Stakeholders often push back on recommendations or guidance from the L&D team
          </li>
        </ul>
      `
    },
    {
      id: '6',
      name: 'Working Culture',
      message: `
        <p class="font-light mt-5 text-sm lg:text-xs lg:w-11/12">
          Working Culture is defined by the key attributes and norms that guide how work gets done.
          Signs you may be facing challenges with your Working Culture include:
        </p>

        <ul class="list-disc text-sm lg:text-xs ml-4 font-light lg:w-11/12">
          <li class="mt-3">
            There is confusion among team members about what the desired culture is – they do not have a good understanding of the desired formal or informal “ways of working”
          </li>
          <li class="mt-3">
            If there is a clearly defined culture, the behaviors that support it are not positively reinforced. For example, an organization that values providing constructive feedback might include delivering feedback in its performance support approach and provide managers with tools to conduct feedback sessions
          </li>
        </ul>
      `
    },
  ];
}
