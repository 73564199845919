import noUiSlider from 'nouislider';
import { connect } from 'react-redux';
import scores from '../consts/scores';
import React, {Component} from 'react';
import PageTemplate from "./PageTemplate";
import {withRouter} from "react-router-dom";
import {getScreenSize} from "../helpers/screens";
import {setResponse, nextQuestion} from '../actions';

import 'nouislider/distribute/nouislider.css';
import '../css/range.css';

class QuestionsPage extends Component {
  /**
   *
   */
  componentDidMount() {
    this.props.nextQuestion();

    const mobile = ['sm', 'md'].includes( getScreenSize() );
    const range = document.getElementById('range-slider');
    const [minScore, _, __, maxScore] = scores;

    noUiSlider.create(range, {
      orientation: mobile ? "vertical" : "horizontal",
      range: {
        min: minScore.value,
        max: maxScore.value,
      },
      start: minScore.value,
      step: 1,
      connect: [true, false],
      pips: {
        mode: 'values',
        values: scores.map(i => i.value),
        density: 30,
        format: {
          // 'to' the formatted value. Receives a number.
          to: function (value) {
            const target = scores.find(i => i.value === value);
            return target.label;
          },
          // 'from' the formatted value.
          // Receives a string, should return a number.
          from: function (value) {
            const target = scores.find(i => i.label === value);
            return target.value;
          }
        }
      }
    });

    const pips = range.querySelectorAll('.noUi-value');

    for (let i = 0; i < pips.length; i++) {
      pips[i].style.cursor = 'pointer';
      pips[i].addEventListener('click', QuestionsPage.handleClickPip);
    }

    range.noUiSlider.on('change', QuestionsPage.handleRangeChange);
    QuestionsPage.handleRangeChange(1);
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.question !== this.props.question && !this.props.question) {
      this.props.history.push('/form');
    }
  }

  /**
   *
   */
  static handleClickPip () {
    const range = document.getElementById('range-slider');
    const value = Number(this.getAttribute('data-value'));

    range.noUiSlider.set(value);
    QuestionsPage.handleRangeChange(value);
  }

  /**
   *
   */
  static handleRangeChange (e) {
    let value = e;
    if (Array.isArray(e)) {
      value = Number( e.pop() );
    }

    const range = document.getElementById('range-slider');
    const pips = range.querySelectorAll('.noUi-value');

    for (let i = 0; i < pips.length; i++) {
      if (pips[i].getAttribute('data-value') == value) {
        pips[i].classList.add('text-brand-primary', 'font-bold');
      } else {
        pips[i].classList.remove('text-brand-primary', 'font-bold');
      }
    }
  }

  /**
   *
   */
  handleBtnNext = () => {
    const range = document.getElementById('range-slider');
    const value = parseInt(range.noUiSlider.get());

    this.props.setResponse(this.props.question, value);
    QuestionsPage.handleRangeChange(1);
    this.props.nextQuestion();
    range.noUiSlider.reset();
  };

  /**
   *
   * @returns {*}
   */
  render() {
    let percent = 100;

    if (this.props.question) {
      let questionIndex = this.props.questions.findIndex(i => i.id === this.props.question.id);
      percent = (questionIndex / this.props.questions.length) * 100;
      percent = parseInt(percent);
    }

    return (
      <PageTemplate>
        <div className="bg-brand-blue relative">
          <div className="arrow-down absolute top-full transform translate-x-1/2 right-1/2"></div>
          <div className="lg:w-7/12 mx-auto">
            <div className="px-8 lg:px-4 pt-8 lg:pt-6 pb-10 lg:pb-12">

              <div className="w-100 lg:max-w-xs mx-auto lg:flex items-center lg:mt-1">
                <div className="h-3 bg-white flex-1">
                  <div className="bg-brand-secondary h-full" style={{width: percent + "%"}}></div>
                </div>
                <div className="pt-2 lg:pt-0 lg:pl-4 text-xs lg:-mr-3 font-light">
                  {percent}%
                </div>
              </div>

              {this.props.question && (
                <p className="lg:text-2xl mt-6 lg:leading-8">
                  {this.props.question.question}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="w-6/12 mx-auto pt-16 pb-10 lg:py-20">
          <div id="range-slider" className="transform scale-90"></div>
          <div className="h-8 md:h-32"></div>
          <div className="text-center">
            <button onClick={this.handleBtnNext}
               className="inline-block hover:shadow-lg relative border-2 border-white rounded-lg gloss-effect transition duration-100 ease-in-out focus:outline-none">
              <span className="flex items-center py-1 lg:py-0 px-3 lg:text-lg relative z-10 font-light">
                Next
                <i className="mdi mdi-arrow-right-bold text-white text-3xl lg:text-2xl ml-1"></i>
              </span>
            </button>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  questions: state.questions,
  question: state.question
});

const mapDispatchToProps = dispatch => ({
  nextQuestion: () => dispatch(nextQuestion()),
  setResponse: (question, response) => dispatch(setResponse(question, response)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(QuestionsPage)
)
