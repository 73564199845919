/**
 *
 * @param errorObj
 * @param key
 * @returns {null|*}
 */
export function getValidationErrors (errorObj, key) {
  if (errorObj && errorObj[key]) {
    if (Array.isArray(errorObj[key])) {
      return errorObj[key][0];
    }
    return errorObj[key];
  }
  return null;
}

/**
 *
 * @param classname
 * @param category
 * @returns {HTMLDivElement}
 */
export function makePyramidSection(classname, category) {
  const newElement = document.createElement('div');
  ['pyramid-level', 'p-3', 'text-center', 'mb-2'].forEach(function (i) {
    newElement.classList.add(i);
  });

  classname.split(' ').forEach(function (i) {
    newElement.classList.add(i);
  });

  newElement.dataset.category = category.id;
  newElement.textContent = category.name;
  return newElement;
}

/**
 *
 * @param element
 * @param classname
 * @returns {boolean|(Node & ParentNode) | (() => (Node | null)) | ActiveX.IXMLDOMNode | boolean|*}
 */
export function hasSomeParentClass(element, classname) {
  if (element.classList && element.classList.contains(classname)) return true;
  return element.parentNode && hasSomeParentClass(element.parentNode, classname);
}

/**
 *
 * @param category
 * @param questions
 * @param maxScore
 * @returns {number}
 */
export function getScorePercent(category, questions, maxScore) {
  const filteredQuestions = questions.filter(question => question.category_id == category.id);
  const categoryTotal = filteredQuestions.length * maxScore.value;
  const scorePercent = (category.score / categoryTotal) * 100;
  return parseInt(scorePercent);
}
