import {Link} from "react-router-dom";
import React, {Component} from 'react';
import PageTemplate from "./PageTemplate";

class HomePage extends Component {
  render() {
    return (
      <PageTemplate>
        <div
          className="-translate-x-20 xl:-translate-x-32 xl:-translate-y-5 absolute hidden left-0 lg:block scale-110 transform w-1/2">
          <img src="./img/hero-introduction.png" alt="" className="w-full transform scale-105"/>
        </div>

        <div className="container mx-auto">
          <div className="lg:w-1/2 ml-auto px-8 lg:px-0 lg:py-10">
            <h1
              className="font-semibold text-xl lg:text-4xl leading-snug lg:leading-none border-b border-gray-400 pt-5 pb-3 lg:pb-6">
              Organizational Strategy Diagnostic
            </h1>
            <img src="./img/hero-introduction.png" alt=""
                 className="-mb-12 -mt-5 -translate-x-3 lg:hidden scale-105 transform"/>
            <div className="lg:pr-5 lg:w-4/5">
              <p className="py-5 text-base lg:text-lg leading-6 lg:leading-normal font-light">
                There are 13 statements in this survey. Rate the extent to which you agree or disagree with each
                statement.
              </p>
              <p className="italic mb-8 text-xs leading-5 lg:leading-normal w-10/12 lg:w-full">
                Note that in the statements that follow, the term “stakeholders” refers to internal customers and senior
                leaders.
              </p>
            </div>
            <Link to="/questions"
               className="mb-10 inline-block hover:shadow-lg relative border-2 border-white rounded-lg gloss-effect transition duration-100 ease-in-out focus:outline-none">
              <span className="flex items-center py-1 lg:py-0 px-3 lg:text-lg relative z-10 font-light">
                Get started
                <i className="mdi mdi-arrow-right-bold text-white text-3xl lg:text-2xl ml-1"></i>
              </span>
            </Link>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

export default HomePage;
