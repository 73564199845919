import React, {Component} from 'react';
import PageTemplate from "./PageTemplate";

class HomePage extends Component {
  render() {
    return (
      <PageTemplate>
        <div
          className="-translate-x-20 xl:-translate-x-32 xl:-translate-y-5 absolute hidden left-0 lg:block scale-110 transform w-1/2">
          <img src="./img/hero-introduction.png" alt="" className="w-full transform scale-105"/>
        </div>

        <div className="container mx-auto">
          <div className="lg:w-1/2 ml-auto px-8 lg:px-0 lg:py-10">
            <h1
              className="font-semibold text-xl lg:text-4xl leading-snug lg:leading-none border-b border-gray-400 pt-5 pb-3 lg:pb-6">
              Thank You
            </h1>
            <img src="./img/hero-introduction.png" alt=""
                 className="-mb-12 -mt-5 -translate-x-3 lg:hidden scale-105 transform"/>
            <div className="lg:pr-5 lg:w-4/5">
              <p className="py-5 text-lg lg:text-2xl leading-6 lg:leading-normal font-light">
              Thanks for requesting more information. We’ll reach out soon via email to learn more about what you’re looking for.
              </p>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

export default HomePage;
