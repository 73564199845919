import "../css/pyramid.css";
import {connect} from "react-redux";
import scores from "../consts/scores";
import React, {Component} from 'react';
import {setCategory} from "../actions";
import {withRouter} from "react-router";
import PageTemplate from "./PageTemplate";
import ScoreCard from "../components/ScoreCard";
import {getScreenSize} from "../helpers/screens";
import {CSSTransition} from "react-transition-group";
import {getScorePercent, hasSomeParentClass, makePyramidSection} from "../helpers/tools";
import classNames from "classnames";

class ResultsCategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
    }
  }

  /**
   *
   */
  componentDidMount() {
    const mobile = ['sm', 'md'].includes( getScreenSize() );

    if (mobile) {
      const stage = document.getElementById("infographic-stage");

      this.props.categories.forEach(category => {
        let color;
        const maxScore = scores.slice().pop();
        const score = getScorePercent(category, this.props.questions, maxScore);

        if (score >= 85) {
          color = 'bg-pyramid-green';
        } else if (score >= 75) {
          color = 'bg-pyramid-yellow';
        } else {
          color = 'bg-pyramid-red text-white';
        }

        stage.appendChild( makePyramidSection(color, category) );
      });
    } else {
      const xhr = new XMLHttpRequest();
      xhr.open("GET","/img/pyramid_heatmap.svg", false);
      // Following line is just to be on the safe side;
      // not needed if your server delivers SVG with correct MIME type
      xhr.overrideMimeType("image/svg+xml");
      xhr.onload = function(e) {
        // You might also want to check for xhr.readyState/xhr.status here
        document.getElementById("lg:infographic-stage")
          .appendChild(xhr.responseXML.documentElement);
      };
      xhr.send("");
    }

    const userSelection = document.getElementsByClassName('pyramid-level');
    const infographicBlur = document.getElementsByClassName('infographic-blur');
    const clickHandler = (e) => {
      if (
        !hasSomeParentClass(e.target, 'pyramid-level') &&
        !hasSomeParentClass(e.target, 'infographic-details')
      ) {
        // reset page
        this.props.setCategory(null);

        for(let i = 0; i < userSelection.length; i++) {
          (function(index) {
            userSelection[index].classList.remove('mute');
          })(i);
        }

        for(let a = 0; a < infographicBlur.length; a++) {
          infographicBlur[a].classList.remove('opacity-25', 'pointer-events-none');
        }

        document.removeEventListener('click', clickHandler);
      }
    };

    for(let i = 0; i < userSelection.length; i++) {
      ((index) => {
        const maxScore = scores.slice().pop();
        const categoryId = userSelection[index].dataset.category;
        const category = this.props.categories.find(category => category.id == categoryId);

        const score = getScorePercent(category, this.props.questions, maxScore);

        if (mobile) {
          userSelection[index].textContent += ' — ' + score + '%';
        } else {
          userSelection[index].querySelector('text').textContent = score + '%';
          if (score >= 85) {
            userSelection[index].querySelector('polygon').classList.add('bg-pyramid-green');
          } else if (score >= 75) {
            userSelection[index].querySelector('polygon').classList.add('bg-pyramid-yellow');
          } else {
            userSelection[index].querySelector('polygon').classList.add('bg-pyramid-red');
          }
        }

        userSelection[index].addEventListener("click", () => {
          this.props.setCategory(category);

          for(let a = 0; a < infographicBlur.length; a++) {
            infographicBlur[a].classList.add('opacity-25', 'pointer-events-none');
          }

          userSelection[index].classList.remove('mute');

          for(let j = 0; j < userSelection.length; j++) {
            if (index !== j) {
              userSelection[j].classList.add('mute');
            }
          }

          document.addEventListener("click", clickHandler);
        })
      })(i);
    }
  }

  /**
   *
   */
  handleBtnClick = () => {
    this.props.history.push('/consultation')
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const btnClass = classNames(
      "mt-5 inline-block hover:shadow-lg relative border-2 border-white rounded-lg gloss-effect transition duration-100 ease-in-out focus:outline-none",
      {"cursor-wait" : this.state.submit}
    );

    return (
      <PageTemplate>
        <div className="lg:container mx-auto flex-1">
          <div className="flex py-6 lg:py-12">
            <div className="lg:w-7/12">
              <div className="origin-top scale-110 transform w-full" id="lg:infographic-stage">
                {/*Fallback Text Content can go here*/}
              </div>
            </div>
            <div className="lg:w-5/12 lg:pt-16 px-6 lg:px-0 lg:relative">
              <CSSTransition in={this.props.category} timeout={200} classNames="my-node">
                <ScoreCard />
              </CSSTransition>

              <h1 className="lg:text-xl xl:text-2xl font-light pb-3 infographic-blur">
                <span className="font-semibold">Click on each category</span> to review some of the challenges you may
                be facing in that category.
              </h1>

              <div id="infographic-stage" className="mt-2 mb-8"></div>

              <div className="pt-4 lg:p-12 xl:pt-20 lg:pl-16 lg:pr-12 xl:pl-24 infographic-blur border-t">
                <h2 className="xl:text-lg font-semibold mb-4">
                  Interested in improving your scores?
                </h2>
                <p className="xl:text-lg font-light w-10/12 lg:w-full">
                Once you’ve reviewed all the categories, click below to learn how you can improve your organization’s strategy.
                </p>
                <button type="button" onClick={this.handleBtnClick} className={btnClass}>
                  <span className="flex items-center py-1 lg:py-0 px-3 lg:text-lg relative z-10 font-light">
                    Learn More
                    <i className="mdi mdi-arrow-right-bold text-white text-3xl lg:text-2xl ml-1"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categories,
  questions: state.questions,
  category: state.category,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setCategory: category => dispatch(setCategory(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(ResultsCategoryPage)
);
