import {connect} from "react-redux";
import scores from "../consts/scores";
import {Link} from "react-router-dom";
import React, {Component} from 'react';
import {setResponse} from "../actions";
import PageTemplate from "./PageTemplate";
import { ReactComponent as Chart } from '../results-chart.svg';


class ResultsOverallPage extends Component {
  /**
   *
   */
  componentDidMount() {
    const ring_teal = document.querySelector('#ring-teal');
    const ring_yellow = document.querySelector('#ring-yellow');
    const radii = [ ring_teal.r.baseVal.value, ring_yellow.r.baseVal.value ];
    const circumferences = radii.map(radius => radius * 2 * Math.PI);

    ring_teal.style.transition = "0.35s stroke-dashoffset";
    ring_teal.style.strokeDashoffset = `${circumferences[0]}`;
    ring_teal.style.strokeDasharray = `${circumferences[0]} ${circumferences[0]}`;

    ring_yellow.style.transition = "0.35s stroke-dashoffset";
    ring_yellow.style.strokeDashoffset = `${circumferences[1]}`;
    ring_yellow.style.strokeDasharray = `${circumferences[1]} ${circumferences[1]}`;

    function setProgress(percent) {
      const ring_percent = percent > 99.75 ? 99.75 : percent;
      ring_teal.style.strokeDashoffset = circumferences[0] - ring_percent / 100 * circumferences[0];
      ring_yellow.style.strokeDashoffset = circumferences[1] - ring_percent / 100 * circumferences[1];
    }

    setProgress(this.scorePercent);
  }

  /**
   *
   * @returns {number}
   */
  get scorePercent() {
    const maxScore = scores.slice().pop();
    const overallScore = this.props.categories
      .map(category => category.score || 0)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalScore = this.props.questions.length * maxScore.value;
    const percent = (overallScore / totalScore) * 100;
    return parseInt(percent);
  }

  /**
   *
   * @returns {*}
   */
  render() {
    return (
      <PageTemplate>
        <div className="h-32 lg:h-48">
          <div className="w-32 lg:w-64 mx-auto lg:mt-2 transform translate-y-10 lg:translate-y-16 scale-125 relative">
            <Chart/>
            <div className="text-2xl lg:text-5xl absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <span>{this.scorePercent}%</span>
            </div>
          </div>
        </div>

        <div className="bg-brand-blue flex-1 text-center py-5 px-8 lg:px-0 overflow-hidden">
          <div className="transform translate-y-10 lg:translate-y-40">
            <h1 className="text-lg lg:text-2xl font-bold">
              Thank you for completing the Survey! <span
              className="text-brand-primary">Your overall score is {this.scorePercent}%.</span>
            </h1>
            <p className="lg:w-6/12 lg:px-5 mx-auto mt-5 lg:text-lg font-light">
              Click “Next” to see your category-specific results to get a sense of what elements of your L&D strategy
              have the most room for improvement.
            </p>
            <Link to="/results/category"
               className="mt-5 inline-block hover:shadow-lg relative border-2 border-white rounded-lg gloss-effect transition duration-100 ease-in-out focus:outline-none">
              <span className="flex items-center py-1 lg:py-0 px-3 lg:text-lg relative z-10 font-light">
                Next
                <i className="mdi mdi-arrow-right-bold text-white text-3xl lg:text-2xl ml-1"></i>
              </span>
            </Link>
            <div className="lg:h-64"></div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categories,
  questions: state.questions,
});

const mapDispatchToProps = dispatch => ({
  setResponse: (question, response) => dispatch(setResponse(question, response)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultsOverallPage);
