import React, {Component} from 'react';
import {withRouter} from "react-router";

class PageTemplate extends Component {
  render() {
    const containerClassname = "min-h-screen relative " + (
      this.props.location.pathname === "/results/overall" ? "flex flex-col" : ""
    );

    return (
      <div className="bg-white h-screen overflow-y-auto">
        <div className={containerClassname}>
          <nav className="bg-white sticky top-0 lg:relative z-20">
            <div className="bg-brand-secondary pb-1 lg:py-4 infographic-blur">
              <div>
                <img src="/img/bcb_logo.svg" alt="" className="h-20 mx-auto transform scale-75 lg:scale-105 lg:mb-1"/>
              </div>
            </div>
          </nav>

          {this.props.children}
        </div>

        <footer className="bg-brand-secondary infographic-blur">
          <p className="text-xs text-white text-center py-4">
            &copy;2020 Bull City Blue
          </p>
        </footer>
      </div>
    );
  }
}

export default withRouter(PageTemplate);
